// 生产环境
let config = {
  baseUrl: 'https://edu.bsia.org.cn/gateway', // 生产网关地址
  tokenName: 'EDU_WEB_TOKEN',
  tokenExpires: 60 * 60 * 1000 // token 过期时间为1个小时
}
if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config = {
    ...config,
    baseUrl: 'https://edu.bsia.org.cn/gateway', // 开发网关地址
    url: 'edu.bsia.org.cn' // 店铺域名，本地开发使用，生产不用配置该属性
  }
}
export default config

